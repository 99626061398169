import { getJudgeMeRatings } from 'api/judge-me';
import laggy from 'api/middleware/laggy';
import { CollectionResponse, isLegacyCollection } from 'api/product';
import { SmartCollectionResponse } from 'api/product/getSmartColleciton/types';
import { isSmartCollectionResponse } from 'api/product/getSmartColleciton/utils';
import { useConfig } from 'config';
import { SegmentationIds } from 'constants/segmentation';
import { SegmentationStore } from 'contexts/segmentation';
import { useEffect, useMemo } from 'react';
import { ParsedReviews, useReviewsCacheStore } from 'stores/reviews-cache';
import useSWR from 'swr';
import { parseReviews } from 'utils/judge-me';
type Input = {
  collection?: CollectionResponse | SmartCollectionResponse;
  segmentation?: SegmentationStore | SegmentationIds;
};

type Output = {
  data?: ParsedReviews;
  isLoading: boolean;
};

/**
 * Hook to fetch judgeMe review badge information of products in Collections
 */
const useJudgeMeReviews = ({ collection, segmentation }: Input): Output => {
  const { judgeMePublicToken, shopifyDomain } = useConfig();

  const swrKey = useMemo(() => {
    if (!collection) return '';
    if (isLegacyCollection(collection)) {
      return collection.handle;
    } else if (segmentation) {
      return typeof segmentation === 'string'
        ? segmentation
        : Object.values(segmentation).flat();
    }
    return collection.slug;
  }, [collection, segmentation]);

  const { setReviews } = useReviewsCacheStore();

  const productIds = useMemo(() => {
    if (!collection) return [];

    return isSmartCollectionResponse(collection)
      ? collection.items.map((item) => item.product_id)
      : collection.products.map((product) => product.id.toString());
  }, [collection]);

  const { data, isValidating, mutate } = useSWR(
    `judge-me-${swrKey}`,
    async () => {
      if (!productIds.length) return undefined;
      const rawReviews = await getJudgeMeRatings({
        externalIds: productIds,
        judgeMePublicToken,
        shopifyDomain,
      });
      if (!rawReviews) return undefined;
      return parseReviews({ rawReviews });
    },
    {
      use: [laggy],
      revalidateOnMount: false,
      onSuccess: (data) => {
        if (!data) return;
        setReviews((prev) => ({ ...prev, ...data }));
      },
    }
  );

  useEffect(() => {
    if (collection) {
      mutate();
    }
  }, [collection, mutate]);

  return {
    data,
    isLoading: isValidating,
  };
};

export default useJudgeMeReviews;
