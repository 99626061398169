import { CollectionResponse } from 'api/product';
import cn from 'classnames';
import ProductCard, { CARD_TYPES } from 'components/product-card';
import ProductCardSkeleton from 'components/product-card/skeleton/index.';
import ProductImpressionsTracker from 'components/product-impressions-tracker';
import { CollectionSorting } from 'constants/collection';
import useMobile from 'hooks/common/use-mobile';
import { CollectionProduct } from 'models/collection/collection';
import {
  CollectionContext,
  CollectionProvider,
} from 'modules/collection/state';
import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { SWRConfiguration } from 'swr';
import { scrollElement } from 'tracking/events';
import { gtm } from 'tracking/gtm';
import style from './style.module.css';

const SLIDES_TO_SHOW = {
  MOBILE: 1,
  MOBILE_BLOG: 2,
  DESKTOP: 4,
  DESKTOP_LARGE: 3,
} as const;

const MAX_PRODUCTS = {
  BLOG: 5,
  HOME: 6,
} as const;

const SKELETON_SLIDES = 5;

interface ICollectionSectionProps {
  collection: string;
  elementNameTracking?: string[];
  filterSubscriptionEnabledItems?: boolean;
  isBlogPost?: boolean;
  isHomePage?: boolean;
  isSpecialLimitedSlider?: boolean;
  itemSize?: 'Normal' | 'Large' | undefined;
  lastSlide?: React.ReactNode;
  shouldShowLoading?: boolean;
  sliderSize?: 'default' | 'medium' | 'large';
  staticCollection?: CollectionResponse;
  swrOptions?: SWRConfiguration;
  onTileClick?: (arg?: CollectionProduct) => void;
}

/**
 * Generic slider for collection.
 * @TODO : Name is misleading as it is a generic component with a different design from CollectionSection (with displayAsSlider on)
 */
const CollectionSectionBestseller: React.FC<ICollectionSectionProps> = ({
  collection,
  elementNameTracking,
  filterSubscriptionEnabledItems,
  isBlogPost = false,
  isHomePage = false,
  lastSlide,
  shouldShowLoading = false,
  sliderSize,
  staticCollection,
  swrOptions,
  onTileClick,
}) => {
  const isMobile = useMobile();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    if (collection) {
      setCurrentIndex(0);
      sliderRef.current?.slickGoTo(0);
    }
  }, [collection]);

  const sliderProps = React.useMemo(() => {
    const variableWidth = isMobile && sliderSize === 'large';

    let slidesToShow: number = SLIDES_TO_SHOW.DESKTOP;
    if (isMobile) {
      slidesToShow = isBlogPost
        ? SLIDES_TO_SHOW.MOBILE_BLOG
        : SLIDES_TO_SHOW.MOBILE;
    } else if (sliderSize === 'large') {
      slidesToShow = SLIDES_TO_SHOW.DESKTOP_LARGE;
    }

    let centerMode = false;
    if (isMobile && isBlogPost) {
      centerMode = false;
    } else if (isMobile) {
      centerMode = true;
    }

    return {
      arrows: !isMobile,
      centerMode: centerMode,
      infinite: false,
      slidesToShow,
      variableWidth,
    };
  }, [isMobile, isBlogPost, sliderSize]);

  const hasTrackedRef = useRef(false);

  const afterChange = (index: number): void => {
    setCurrentIndex(index);
    if (!hasTrackedRef.current) {
      hasTrackedRef.current = true;
      gtm(
        scrollElement({
          element: ['Collection Section Bestseller', collection],
        })
      );
    }
  };

  return (
    <CollectionProvider
      collection={staticCollection}
      collectionHandle={collection || ''}
      sortBy={CollectionSorting.DEFAULT}
      swrOptions={swrOptions}
    >
      <CollectionContext.Consumer>
        {(val) => {
          if (shouldShowLoading && val.isValidating) {
            return (
              <div className={style.slickContainer}>
                <Slider dots={false} ref={sliderRef} {...sliderProps}>
                  {new Array(SKELETON_SLIDES).fill(null).map((_, index) => (
                    <div className="px-2 py-2" key={index}>
                      <ProductCardSkeleton isSlider />
                    </div>
                  ))}
                </Slider>
              </div>
            );
          }

          if (!val.collection) {
            return '';
          }

          let products = val.collection.products.filter(
            (p) => p.availableForSale
          );

          const maxProducts = isBlogPost
            ? MAX_PRODUCTS.BLOG
            : isHomePage
              ? MAX_PRODUCTS.HOME
              : products.length;
          products = products.slice(0, maxProducts);

          const filteredProducts = filterSubscriptionEnabledItems
            ? products.filter((product) =>
                product.tags.includes('SubscriptionEnabled')
              )
            : products;

          return (
            <div className={style.slickContainer}>
              <Slider
                afterChange={afterChange}
                dots={false}
                ref={sliderRef}
                {...sliderProps}
              >
                {filteredProducts.map((product, index: number) => {
                  return (
                    <ProductImpressionsTracker
                      collectionHandle={collection}
                      element={
                        (elementNameTracking && elementNameTracking[0]) ??
                        'Collection Section Bestseller'
                      }
                      key={`${product.id}-${product.promoteVariantId}`}
                      list={val.collection.title}
                      position={index}
                      product={product}
                      trackingGroupName={collection}
                    >
                      <div
                        className={cn(
                          'mx-2 h-full',
                          sliderSize === 'large' && 'w-[90%]'
                        )}
                      >
                        <ProductCard
                          collectionTitle={val.collection.title}
                          position={index}
                          product={product}
                          starSize={
                            sliderSize === 'large' || !isMobile
                              ? 'medium'
                              : 'small'
                          }
                          type={
                            sliderSize === 'large'
                              ? CARD_TYPES.SLIDER_LARGE
                              : CARD_TYPES.SLIDER
                          }
                          {...(onTileClick
                            ? {
                                onTileClick: () => {
                                  onTileClick(product);
                                },
                              }
                            : {})}
                        />
                      </div>
                    </ProductImpressionsTracker>
                  );
                })}
                {lastSlide && <div className="h-full">{lastSlide}</div>}
              </Slider>
            </div>
          );
        }}
      </CollectionContext.Consumer>
    </CollectionProvider>
  );
};

export default CollectionSectionBestseller;
