import laggy from 'api/middleware/laggy';
import { CollectionResponse } from 'api/product';
import { getSmartCollection } from 'api/product/getSmartColleciton';
import { SmartCollectionResponse } from 'api/product/getSmartColleciton/types';
import { isSmartCollectionResponse } from 'api/product/getSmartColleciton/utils';
import { BasicPDEXInterface } from 'api/product/types';
import { useConfig } from 'config';
import { FeatureFlag, getFeatureFlag } from 'constants/feature-flag';
import { debounce } from 'lodash';
import { CollectionPropTypes } from 'modules/collection/types/CollectionPropTypes';
import { useEffect, useRef, useState } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

interface InputBaseType extends BasicPDEXInterface {
  collectionDefault?: SmartCollectionResponse | CollectionResponse;
  swrOptions?: SWRConfiguration;
}

type Input = CollectionPropTypes<InputBaseType>;

type SWRKey = [
  Input['collectionHandle'],
  Input['segmentation'],
  Input['smartCollectionInput'],
  Input['ninetailed'],
];

const useCollectionFetch = ({
  collectionDefault,
  swrOptions,
  collectionHandle,
  segmentation,
  smartCollectionInput,
  ninetailed,
}: Input) => {
  const { shopId } = useConfig();
  const featureFlg = getFeatureFlag(
    FeatureFlag.smartCollectionEndpoint,
    shopId
  );

  const [data, setData] = useState<
    CollectionResponse | SmartCollectionResponse | undefined
  >(collectionDefault);

  const [isLoading, setIsLoading] = useState(false);

  // Ref to store the latest ninetailed value after debounce
  const debouncedNinetailed = useRef(ninetailed);

  const debounceNinetailedUpdate = debounce((value) => {
    debouncedNinetailed.current = value;
  }, 500);

  useEffect(() => {
    debounceNinetailedUpdate(ninetailed);
  }, [ninetailed, debounceNinetailedUpdate]);

  /**
   * Refetch Data on client side so we always get updated data
   */
  const { isValidating } = useSWR(
    () => {
      return [
        collectionHandle,
        segmentation,
        smartCollectionInput,
        debouncedNinetailed.current,
      ] as SWRKey;
    },
    async ([
      collectionHandle,
      segmentation,
      smartCollectionInput,
      ninetailed,
    ]: SWRKey) => {
      setIsLoading(true);
      if (!collectionHandle && !segmentation && !smartCollectionInput) {
        throw new Error('no handle and segmentation is assigned');
      }

      if (smartCollectionInput) {
        return getSmartCollection(
          { ...smartCollectionInput, ninetailed },
          shopId
        );
      }

      if (collectionHandle) {
        return getSmartCollection(collectionHandle, shopId, true);
      }

      throw new Error('no handle and segmentation is assigned');
    },
    {
      fallback: {
        ...(collectionHandle ? { [collectionHandle]: collectionDefault } : {}),
      },
      use: [laggy],
      ...swrOptions,
      onSuccess: (data) => {
        // For CH we return the data as CollectionResponse.
        if (!featureFlg) setData(data);
        // On some cases where collection import is not done correctly, we show default collection as fall back
        if (isSmartCollectionResponse(data) && data.items.length > 0) {
          setData(data);
          // And in other cases the fallback is undefined, since collectionDefault could be undefined as well.
          // In this event, we return the data as CollectionResponse.
        } else if (!collectionDefault && !isSmartCollectionResponse(data)) {
          setData(data);
        }
        setIsLoading(false);
      },
    }
  );

  return {
    data,
    isLoading: isLoading || isValidating,
  };
};

export default useCollectionFetch;
