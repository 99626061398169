import { CollectionResponse } from 'api/product';
import {
  isSmartCollectionInputWithSlug,
  SmartCollectionResponse,
} from 'api/product/getSmartColleciton/types';
import { CollectionSorting } from 'constants/collection';
import { PetTag } from 'constants/product';
import { isCatSegmentation, SegmentationIds } from 'constants/segmentation';
import { usePetContext } from 'contexts/pet';
import { useSegmentationContext } from 'contexts/segmentation';
import {
  CatNutritionalNeedsTags,
  FilterConfig,
  NutritionalNeedsTags,
} from 'interfaces/collection-filter';
import orderBy from 'lodash/orderBy';
import { CollectionProduct } from 'models/collection/collection';
import {
  Collection,
  transformCollection,
} from 'models/collection/transformCollection';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SWRConfiguration } from 'swr';
import { slugify } from 'utils/strings';
import useApplyFilterFromUrl from '../hooks/use-apply-filter-from-url';
import useJudgeMeReviews from '../hooks/use-judge-me-reviews';
import useRequestUrlUpdate from '../hooks/use-request-url-update';

import { useConfig } from 'config';
import { useNinetailedAbTestContext } from 'contexts/ninetailed-ab-test';
import { useTrackingContext } from 'contexts/tracking';
import useCollectionFetch from '../hooks/use-collection-fetch';
import { CollectionPropTypes } from '../types/CollectionPropTypes';
import getAllFilter from '../utils/get-all-filter';
import { modifyConfig } from '../utils/modify-config/const';
import { useVisibleProductList } from './visible-products';

interface PropTypeBase {
  collection?: SmartCollectionResponse | CollectionResponse;
  sortBy?: CollectionSorting;
  tags?: string[];
  children: React.ReactNode;
  swrOptions?: SWRConfiguration;
  /** Name given to impression tracker */
  trackingListName?: string;
  /** Helper to propagate tags to parent component  */
  setUsedTags?: React.Dispatch<React.SetStateAction<string[]>>;
  applyPetProfile?: boolean;
}

type PropTypes = CollectionPropTypes<PropTypeBase>;

interface CollectionContextTypes {
  activeAllergyFilter: string[];
  appliedAllergyFilter: string[];
  appliedFilter: string[];
  collection: Collection;
  filterConfig: FilterConfig | undefined;
  isValidating: boolean;
  limitOfProductsToShow?: number;
  sorting: CollectionSorting;
  visibleProducts: CollectionProduct[];
  resetFilter: () => void;
  setLimitOfProductsToShow: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  toggleActiveAllergyFilter: (value: string) => void;
  toggleAllergyFilter: (value: string) => void;
  toggleFilter: (value: string) => void;
  /** The length of products, which is independent of limitOfProductsToShow */
  productsLength: number;
  /** Name given to impression tracker, if not specified, product handle could be given */
  trackingListName?: string;
}

export const CollectionContext = React.createContext<CollectionContextTypes>(
  {} as CollectionContextTypes
);

/**
 * Map of filter to segmentation
 */
const SEGMENTS_FILTER_MAP: {
  [key in NutritionalNeedsTags | CatNutritionalNeedsTags]:
    | SegmentationIds
    | Array<SegmentationIds>;
} = {
  'bestimmte-erkrankung': 'disease',
  'haut-and-fellprobleme': 'skin-fur',
  'sehr-wahlerisch': [
    SegmentationIds.PickyEater,
    SegmentationIds.CatPickyEater,
  ],
  'senior-7-jahre': 'senior',
  'unvertraglichkeiten-allergien': 'intolerance',
  verdauungsprobleme: [SegmentationIds.Digestion, SegmentationIds.CatDigestion],
  welpe: 'welpe',
  ubergewicht: [SegmentationIds.Overweight, SegmentationIds.CatOverweight],
  steriliziert: SegmentationIds.Sterilized,
  freiganger: SegmentationIds.Outdoor,
  kitten: SegmentationIds.Kitten,
  senior: SegmentationIds.CatSenior,
};

/**
 * Filter value set for segmentation
 */
const SEGMENT_FILTERS_SET = new Set(Object.keys(SEGMENTS_FILTER_MAP));

const sortCollection = (
  collection: Collection,
  sorting: CollectionSorting
): Collection => {
  let sortedProducts = collection.products;

  if (sorting === CollectionSorting.TITLE) {
    sortedProducts = orderBy(
      collection.products,
      (product) => product.title.trim(),
      'asc'
    );
  }

  if (
    [
      CollectionSorting.PRICE_ASCENDING,
      CollectionSorting.PRICE_DESCENDING,
    ].includes(sorting)
  ) {
    sortedProducts = orderBy(
      collection.products,
      (product) => parseFloat(product.variants[0].priceV2.amount),
      sorting === CollectionSorting.PRICE_ASCENDING ? 'asc' : 'desc'
    );
  }

  if (sorting === CollectionSorting.NEW) {
    sortedProducts = orderBy(
      collection.products,
      (product) => new Date(product.createdAt),
      'asc'
    );
  }

  if (sorting === CollectionSorting.BEST_SELLING) {
    sortedProducts = orderBy(collection.products, () => 0, 'asc');
  }

  return { ...collection, products: sortedProducts };
};

/**
 * Collection Context provider.
 * Filter values should be updated via only url updates.
 * so that always the flow is streamlined in url -> appliedFilter.
 *
 * This context accepts either collection handle, segmentation store or dynamic collection input
 *
 * @TODO : trackingListName is provisional implementation. waiting the answer from chris
 */
export const CollectionProvider: React.FC<PropTypes> = ({
  children,
  tags: initialTags = [],
  collection: collectionDefault,
  sortBy,
  swrOptions = {},
  trackingListName,
  setUsedTags,
  applyPetProfile,
  ...others
}) => {
  const { shopId } = useConfig();
  const { segmentation, collectionHandle, smartCollectionInput } = others;
  const { experimentHeaderStr } = useNinetailedAbTestContext();
  const { data, isLoading } = useCollectionFetch({
    collectionDefault,
    swrOptions,
    ninetailed: experimentHeaderStr?.split(','),
    // others alias is used to keep the type assertion
    ...others,
  });

  const { trackHotjarEvent } = useTrackingContext();
  const { current } = usePetContext();
  const router = useRouter();
  const isCDP = useMemo(
    () => router.pathname === '/collections/[...all]',
    [router.pathname]
  );

  const {
    segmentation: _segmentation,
    shouldOmitPersonalization,
    pushSegmentation,
  } = useSegmentationContext();

  const [limitOfProductsToShow, setLimitOfProductsToShow] = useState<number>();

  useJudgeMeReviews({ collection: data, segmentation });

  const transformedCollection = React.useMemo(() => {
    if (!data) {
      return transformCollection({
        title: '',
        collection: {
          title: '',
          products: [],
        },
        shopId,
      });
    }

    return transformCollection({
      collection: data,
      shopId,
    });
  }, [data, shopId]);

  const petType = useMemo(() => {
    const isDog = transformedCollection.products.some(
      (p) => p.tags.indexOf(PetTag.Dog) > -1
    );
    return isDog ? 'dog' : 'cat';
  }, [transformedCollection.products]);

  const filterConfig = useMemo(() => {
    let handle = collectionHandle;

    if (
      !handle &&
      smartCollectionInput &&
      isSmartCollectionInputWithSlug(smartCollectionInput)
    ) {
      handle = smartCollectionInput.slug;
    }
    // apply all available filters when handle is not available
    if (!handle) {
      return getAllFilter({ petType });
    }

    return modifyConfig({
      filterConfig: getAllFilter({ petType }),
      isPetProfileApplied: applyPetProfile,
      handle,
    });
  }, [
    applyPetProfile,
    collectionHandle,
    petType,
    smartCollectionInput,
  ]) as CollectionContextTypes['filterConfig'];

  const [sorting, setSorting] = useState<CollectionSorting>(
    CollectionSorting.DEFAULT
  );

  const collection = useMemo(() => {
    return sortCollection(transformedCollection, sorting);
  }, [transformedCollection, sorting]);

  useEffect(() => {
    setSorting(sortBy || CollectionSorting.DEFAULT);
  }, [sortBy, data]);

  /**
   * Filter Logic
   */
  const [appliedFilter, setAppliedFilter] = useState<string[]>(
    initialTags.map((tag: string) => slugify(tag))
  );

  const [appliedAllergyFilter, setAppliedAllergyFilter] = useState<string[]>(
    []
  );

  // Since the allregy filter also include a value that is toggled in the standard way,
  // we need to keep track of the active allergy filter separately.
  const [activeAllergyFilter, setActiveAllergyFilter] = useState<string[]>([]);
  const toggleActiveAllergyFilter = useCallback(
    (value: string) => {
      const newFilter = activeAllergyFilter.includes(value)
        ? activeAllergyFilter.filter((filter) => filter !== value)
        : [...activeAllergyFilter, value];
      setActiveAllergyFilter(newFilter);
    },
    [activeAllergyFilter]
  );

  /**
   * Apply filter values via url params.
   */
  useApplyFilterFromUrl({
    setAppliedFilter,
    isReady: router.isReady,
    targetQuery: router.query['all'],
  });

  /** Propagate used tags to parent  */
  useEffect(() => {
    if (setUsedTags) {
      setUsedTags([...appliedFilter]);
    }
  }, [appliedFilter, setUsedTags]);

  const visibleProducts = useVisibleProductList({
    collection: collection,
    activeFilter: appliedFilter,
    activeAllergiesFilter: appliedAllergyFilter,
    filterConfig: filterConfig,
    segmentation: _segmentation,
    shouldOmitPersonalization: shouldOmitPersonalization,
  });

  /**
   * Due to the time lag between url update and applied filter update,
   * applied filter are previous values even after url is updated.
   * so here, we reset values when it's a non-shallow route change
   * */
  useEffect(() => {
    const handleRouteChange = (url, { shallow }): void => {
      if (!shallow) {
        setAppliedFilter([]);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  const handle = useMemo(() => {
    try {
      if (collectionHandle) {
        return collectionHandle;
      }
      return router.isReady ? router.query.all[0] : undefined;
    } catch (error) {
      return undefined;
    }
  }, [collectionHandle, router.isReady, router.query.all]);

  const { requestURLUpdate } = useRequestUrlUpdate({
    baseUri: `/collections/${handle}`,
    excludeKey: 'all',
  });

  const initialFilterClick = useRef(false);

  /**
   * Toggles a filter value and updates applied filters and segmentation.
   * Updates applied filters and segmentation based on whether the filter value is added or removed.
   * For Collection pages, it updates the URL; otherwise, it updates applied filters directly.
   */
  const toggleFilter = useCallback(
    (value: string) => {
      const newFilter = appliedFilter.includes(value)
        ? appliedFilter.filter((filter) => filter !== value)
        : [...appliedFilter, value];

      initialFilterClick.current = true;
      isCDP ? requestURLUpdate(newFilter) : setAppliedFilter(newFilter);
    },
    [appliedFilter, isCDP, requestURLUpdate]
  );

  /**
   * Check logics here
   */
  useEffect(() => {
    const filtered = appliedFilter.filter((v) => SEGMENT_FILTERS_SET.has(v));
    if (filtered.length > 0) {
      const segmentFilter = SEGMENTS_FILTER_MAP[filtered[filtered.length - 1]];
      if (typeof segmentFilter === 'string') {
        trackHotjarEvent();
        pushSegmentation({ segmentation: segmentFilter });
      } else if (Array.isArray(segmentFilter) && current !== false) {
        const segmentation = (segmentFilter as Array<SegmentationIds>).find(
          (filter) => {
            if (current === 'dogs') {
              return !isCatSegmentation(filter);
            } else {
              return isCatSegmentation(filter);
            }
          }
        );
        segmentation && pushSegmentation({ segmentation: segmentation });
      }
    }
  }, [appliedFilter, collectionHandle, current, pushSegmentation]);

  const toggleAllergyFilter = useCallback(
    (value: string) => {
      const newFilter = appliedAllergyFilter.includes(value)
        ? appliedAllergyFilter.filter((filter) => filter !== value)
        : [...appliedAllergyFilter, value];
      setAppliedAllergyFilter(newFilter);
    },
    [appliedAllergyFilter]
  );

  const resetFilter = useCallback(() => {
    isCDP ? requestURLUpdate([]) : setAppliedFilter([]);
    setActiveAllergyFilter([]);
    setAppliedAllergyFilter([]);
  }, [isCDP, requestURLUpdate]);

  // If the products are not displayed in the CDP, we need to filter out the
  // Purpose boxes.
  const filteredVisibleProducts = useMemo(
    () =>
      isCDP
        ? visibleProducts
        : visibleProducts.filter(
            (product) =>
              !(
                product.tags.includes('Probierpaket') ||
                product.tags.includes('Purpose Box') ||
                product.tags.includes('Bundle')
              )
          ),
    [isCDP, visibleProducts]
  );

  return (
    <CollectionContext.Provider
      value={{
        activeAllergyFilter,
        appliedAllergyFilter,
        appliedFilter,
        collection,
        filterConfig,
        isValidating: isLoading,
        limitOfProductsToShow,
        sorting,
        visibleProducts: filteredVisibleProducts,
        resetFilter,
        setLimitOfProductsToShow,
        toggleActiveAllergyFilter,
        toggleAllergyFilter,
        toggleFilter,
        productsLength: visibleProducts.length,
        trackingListName: trackingListName ?? handle ?? 'list not identified',
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

/** Provide collection context  */
export const useCollectionState = () => useContext(CollectionContext);
